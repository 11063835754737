import {
  WorkspaceRoleEnum,
  type MembershipRoleT,
} from '@mentimeter/http-clients';

export const adminRole: MembershipRoleT = {
  name: WorkspaceRoleEnum.ADMIN,
  description: 'Can create, edit, and engage participants without limitations.',
  displayName: 'Admin',
  dropdownName: 'Admin',
  isDefault: true,
};

export const memberRole: MembershipRoleT = {
  name: WorkspaceRoleEnum.USER,
  description: 'Can create, edit, and engage participants without limitations.',
  displayName: 'Member',
  dropdownName: 'Member',
  isDefault: true,
};

export const memberLiteRole: MembershipRoleT = {
  name: WorkspaceRoleEnum.MEMBER_LITE,
  description:
    'Can create and edit with free features, and engage a limited number of participants.',
  displayName: 'Member lite',
  dropdownName: 'Member lite (free)',
  isDefault: true,
};
