export const OUT_OF_LICENSES_COPY = {
  both_for_admin: {
    title: 'Out of licenses and Member lite seats',
    description:
      'To invite full members or admins, you need to purchase additional licenses. If you aim to add more free Member lites, please contact our sales team.',
  },
  member_lite_for_user: {
    title: 'Your workspace reached the limit of free members',
    description:
      'If you aim to add more free Member lites, please contact our sales team.',
  },
};

export const getOutOfLicensesCopy = ({
  availableLicenses,
  availableFreeSeats,
}: {
  availableFreeSeats: number;
  availableLicenses: number;
}) => {
  const outOfPaidLicenses = availableLicenses <= 0;
  const outOfMemberLiteSeats = availableFreeSeats <= 0;
  if (outOfPaidLicenses && outOfMemberLiteSeats) {
    return OUT_OF_LICENSES_COPY['both_for_admin'];
  }

  return;
};
