import React, { useEffect, useState } from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { Text } from '../text';
import { IconButton } from '../icon-button';
import { Avatar, type AvatarT } from '../avatar';
import { Tooltip } from '../tooltip';
import { Label } from '../label';
import { ChipInputItem } from './ChipInputItem';
import { chipVariants, chipTextVariants } from './Chip.variants';

export interface ChipT {
  value: string;
  id: string;
  icon?: React.ReactNode;
  color?: string;
  tooltip?: string;
  secondaryText?: string;
  isValid?: boolean;
  name?: string;
  editable?: boolean | undefined;
}

export type ChipAvatarT = Pick<AvatarT, 'initials' | 'name'>;

export const Chip = ({
  option,
  dismissOption,
  isValid = true,
  disabled = false,
  avatar,
  placeholder,
  updateOption,
  onDismissed,
  onPaste,
}: {
  option: ChipT;
  dismissOption: (option: ChipT) => void;
  isValid?: boolean | undefined;
  disabled?: boolean | undefined;
  avatar?: ChipAvatarT;
  placeholder?: string | undefined;
  updateOption?: ((option: ChipT) => void) | undefined;
  onDismissed?: (() => void) | undefined;
  onPaste?: ((e: any) => void) | undefined;
}) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [chipSearchQuery, setChipSearchQuery] = useState<string>('');
  const [chipsWidth, setChipsWidth] = useState<string>('100px');

  const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setChipSearchQuery(value);
  };

  const onTextInputBlur = () => {
    if (chipSearchQuery && updateOption) {
      updateOption({
        ...option,
        value: chipSearchQuery,
      });
    }
    setEditable(false);
  };

  const onTextInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && chipSearchQuery !== '' && updateOption) {
      setEditable(false);
      updateOption({
        ...option,
        value: chipSearchQuery,
      });
    } else if (
      event.key === 'Backspace' &&
      chipSearchQuery.length === 0 &&
      onDismissed
    ) {
      setEditable(false);
      dismissOption(option);
      onDismissed();
    }
  };

  useEffect(() => {
    const chipElement = document.getElementById(`chips-id-${option.id}`);
    if (chipElement) {
      const spanWidth = chipElement.offsetWidth;
      setChipsWidth(`${spanWidth.toString()}px`);
    }
  }, [option]);

  const defaultClasses = [
    'bg-surface',
    'rounded-full',
    'py-2',
    'pl-4',
    'pr-1',
    'max-h-[40px]',
    'w-full',
    'inline-flex',
  ];
  const isEditable = Boolean(updateOption && onDismissed && onPaste);
  const chipVariantClasses = chipVariants({ isValid, isEditable });
  const chipTextVariantClasses = chipTextVariants({ isValid });

  return editable ? (
    <Label className="mb-0">
      <span className="sr-only">Edit {chipSearchQuery}</span>
      <ChipInputItem
        key={`edit-mode-${option.id}`}
        id={`edit-mode-${option.id}`}
        value={chipSearchQuery}
        disabled={disabled}
        name={`autocomplete-${option.id}`}
        placeholder={`edit ${placeholder}`}
        onChange={onTextInputChange}
        onBlur={onTextInputBlur}
        onKeyDown={onTextInputKeyDown}
        onPaste={onPaste}
        className={clsx(['w-[var(--chip-input-item-width)]'])}
        style={
          {
            '--chip-input-item-width': chipsWidth,
          } as React.CSSProperties
        }
      />
    </Label>
  ) : (
    <Tooltip
      key={`${option.id}-tooltip`}
      trigger={
        <Box className="relative max-w-full">
          <Box
            className={clsx(defaultClasses, chipVariantClasses, 'pr-10')}
            {...(isEditable && {
              role: 'button',
              tabIndex: disabled ? -1 : 0,
              'aria-disabled': disabled,
              onDoubleClick: () => {
                if (!disabled) {
                  setEditable(true);
                  setChipSearchQuery(option.value);
                }
              },
              onKeyDown: (event) => {
                if (!disabled && (event.key === 'Enter' || event.key === ' ')) {
                  setEditable(true);
                  setChipSearchQuery(option.value);
                }
              },
            })}
          >
            <Box
              className={clsx([
                'flex-row',
                'items-center',
                'flex-auto',
                'w-full',
              ])}
            >
              {option.icon && (
                <Box
                  id={`${option.id}-icon`}
                  className={clsx([
                    'bg-[var(--chip-icon-bg-color)]',
                    'h-[22px]',
                    'w-[22px]',
                    'rounded-full',
                    'items-center',
                    'justify-center',
                    'mr-2',
                  ])}
                  style={
                    {
                      '--chip-icon-bg-color': option.color,
                    } as React.CSSProperties
                  }
                >
                  {option.icon}
                </Box>
              )}

              {!option.icon && avatar ? (
                <Avatar
                  size="compact"
                  type="user"
                  name={avatar.name}
                  initials={avatar.initials}
                  className="mr-2"
                />
              ) : null}
              <Text className={chipTextVariantClasses} truncate>
                {(isValid && option.secondaryText) || option.value}
              </Text>
            </Box>
          </Box>

          <IconButton
            size="compact"
            variant="secondary"
            borderRadius="full"
            onClick={() => dismissOption(option)}
            disabled={disabled}
            aria-label={`Remove ${
              (isValid && option?.secondaryText) || option.value
            }`}
            className={clsx([
              'absolute',
              'right-1',
              'top-1/2',
              'transform',
              '-translate-y-1/2',
            ])}
          >
            <CrossIcon color="inherit" />
          </IconButton>
        </Box>
      }
    >
      {option.tooltip}
    </Tooltip>
  );
};
