import { CheckoutModal } from '@mentimeter/checkout-modal';
import { type PlanCategory } from '@mentimeter/http-clients';

export const CheckoutModalWrapper = ({
  showLicensesModal,
  setShowLicensesModal,
  planCategory,
}: {
  showLicensesModal: boolean;
  setShowLicensesModal: (show: boolean) => void;
  planCategory: PlanCategory | undefined;
}) => {
  if (!planCategory) return null;

  return (
    <CheckoutModal
      open={showLicensesModal}
      onOpenChange={setShowLicensesModal}
      plan={planCategory}
      type="licenses"
      isCheckoutModalEnabled={true}
    />
  );
};
