import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { type TextInputItemT, TextInputItem } from '../input/text-input';

export const ChipInputItem = React.forwardRef<HTMLInputElement, TextInputItemT>(
  ({ className, ...rest }, ref) => {
    const defaultClasses = [
      'ml-0',
      'rounded-full',
      'min-h-[40px]',
      'min-w-[150px]',
      'h-[40px]',
      'py-2',
      'px-4',
      'w-auto',
      'leading-normal',
    ];

    return (
      <TextInputItem
        ref={ref}
        autoFocus
        {...rest}
        className={clsx(defaultClasses, className)}
      />
    );
  },
);
