import { useCallback, useState } from 'react';
import { useUser } from '@mentimeter/user';
import { TrackingContext } from '@mentimeter/http-clients';
import { useFeatures } from '@mentimeter/workspace-features';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { contactSalesButton, ContactSalesModal } from '@mentimeter/workspace';
import { trackUser } from '@api/tracking/client';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import type { VariantT } from '@mentimeter/ragnar-ui/button';
import { CalloutCard } from '@mentimeter/ragnar-ui/callout-card';
import { getOutOfLicensesCopy } from '../notification-banner/getLicensesNotificationCopy';
import type { InviteMembersContextT } from '../InviteMembersModal';
import { CheckoutModalWrapper } from './CheckoutModalWrapper';

export const INVITE_SELECTED_EMAILS_KEY = 'invite-selected-emails-key';

export const NoLicensesLeftNotification = ({
  context,
}: {
  context: InviteMembersContextT;
}) => {
  const { user } = useUser();
  const { subscriptions } = useSubscriptions();
  const { planCategory } = billingRules(subscriptions);
  const { data: workspace } = useWorkspace();
  const [showSalesModal, setShowSalesModal] = useState(false);
  const [showLicensesModal, setShowLicensesModal] = useState(false);
  const { hasLicenseSelfService } = useFeatures();
  const availableLicenses = workspace?.availableLicenses ?? 0;
  const availableFreeSeats = workspace?.availableFreeSeats ?? 0;

  const buttonContext =
    context === TrackingContext.ManageMembers
      ? TrackingContext.InviteMembers
      : context;

  const handleClickBuyLicenses = useCallback(() => {
    setShowLicensesModal(true);
    trackUser({
      event: 'Clicked buy licenses',
      properties: { context: 'Invite modal' },
    });
  }, [setShowLicensesModal]);

  if (!user) {
    return null;
  }

  const noLicensesLeftCopy = getOutOfLicensesCopy({
    availableFreeSeats,
    availableLicenses,
  });

  if (!noLicensesLeftCopy) {
    return null;
  }

  const contactSalesAction = contactSalesButton({
    variant: 'primary',
    trackingContext: buttonContext,
    onClick: () => {
      setShowSalesModal(true);
    },
  });

  const buyMoreLicensesAction = {
    key: 'upgrade',
    variant: 'primary' as VariantT,
    children: 'Buy more licenses',
    onClick: handleClickBuyLicenses,
    'data-testid': 'buy-licenses-button',
  };

  const selfServiceActions = [buyMoreLicensesAction, contactSalesAction];
  return (
    <>
      <ContactSalesModal
        showSalesModal={showSalesModal}
        setShowSalesModal={setShowSalesModal}
        trackingContext={buttonContext}
      />
      <CalloutCard
        mt={2}
        theme="brand"
        title={noLicensesLeftCopy.title}
        description={noLicensesLeftCopy.description}
        actions={
          hasLicenseSelfService ? selfServiceActions : [contactSalesAction]
        }
      />
      <CheckoutModalWrapper
        showLicensesModal={showLicensesModal}
        setShowLicensesModal={setShowLicensesModal}
        planCategory={planCategory}
      />
    </>
  );
};
