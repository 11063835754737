import { cva } from '@mentimeter/ragnar-tailwind-config';

export const chipVariants = cva(['border-solid'], {
  variants: {
    isValid: {
      true: ['border-[1px]', 'border-weak'],
      false: ['border-[2px]', 'border-negative', 'bg-negative-weakest'],
    },
    isEditable: {
      true: [
        'focus-visible:border-[1px]',
        'focus-visible:border-secondary',
        'focus-visible:shadow-[inset_0_0_0_1px_theme(colors.secondary.DEFAULT)]',
        'focus-visible:outline',
        'focus-visible:outline-4',
        'focus-visible:outline-offset-2',
        'focus-visible:outline-interactive-focused',
      ],
      false: [],
    },
  },
});

export const chipTextVariants = cva([], {
  variants: {
    isValid: {
      true: ['text'],
      false: ['text-on-negative-weakest'],
    },
  },
});
