import React from 'react';
import {
  FixedSizeList as List,
  type ListChildComponentProps,
} from 'react-window';
import type { JSX } from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../box/';
import { Clickable } from '../clickable';
import { Text } from '../text';
import { Label } from '../label';
import { Chip } from '../chip';
import { type ChipT } from '../chip';
import { TransparentInput } from '../transparent-input';
import {
  PopoverAnchor,
  PopoverBody,
  PopoverContainer,
  PopoverRoot,
} from '../popover';

const ITEM_SIZE = 40;
const DEFAULT_MAX_VISIBLE_ITEMS = 5;

export interface ChipsInputT<T extends ChipT> extends BoxT {
  id: string;
  placeholder?: string | undefined;
  label?: string | undefined;
  showOptionsOnFocus?: boolean | undefined;
  autoCompleteOptions?: T[] | undefined;
  autoComplete?: boolean | undefined;
  autoFocus?: boolean | undefined;
  hintText?: string | undefined;
  hintErrorText?: string | undefined;
  hintError?: boolean | undefined;
  disabled?: boolean | undefined;
  renderAutocompleteOption?: ((chip: T) => JSX.Element | string) | undefined;
  maxAutocompleteOptionsVisible?: number;
  setSelectedOptions: (selected: ChipT[]) => void;
  selectedOptions: ChipT[];
  transformOption?: ((chip: ChipT) => ChipT) | undefined;
  onItemsRendered?:
    | (({ visibleStopIndex }: { visibleStopIndex: number }) => void)
    | undefined;
}

interface OptionProps {
  onAutocomplete(item: ChipT): void;
  renderAutocompleteOption(item: ChipT): JSX.Element;
  item: ChipT;
  style: React.CSSProperties;
}

const Option = React.forwardRef<HTMLDivElement, OptionProps>(
  ({ onAutocomplete, renderAutocompleteOption, item, style }, ref) => (
    <Clickable
      key={item.id}
      onClick={() => {
        onAutocomplete(item);
      }}
      className={clsx([
        'flex-row',
        'w-full',
        'h-[var(--chips-input-options-height)px]',
        'top-[var(--chips-input-options-top)px]',
        'items-center',
        'justify-between',
        '[&:enabled]:hover:bg-secondary-weak',
        '[&:enabled]:hover:opacity-100',
        '[&:enabled]:focus-visible:bg-secondary-weak',
        '[&:enabled]:focus-visible:text-on-secondary-weak',
        '[&:enabled]:focus-visible:hover:bg-secondary-weak',
        '[&:enabled]:focus-visible:outline-transparent',
      ])}
      style={
        {
          '--chips-input-options-height': style.height,
          '--chips-input-options-top': style.top,
          ...style,
        } as React.CSSProperties
      }
      ref={ref}
    >
      {renderAutocompleteOption(item)}
    </Clickable>
  ),
);

const InnerListElement = (
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>,
) =>
  React.forwardRef<HTMLDivElement, any>((props, ref) => {
    return <div {...props} ref={ref} onKeyDown={onKeyDown} tabIndex={0} />;
  });

const ChipsInput = <T extends ChipT>({
  id,
  showOptionsOnFocus = false,
  autoCompleteOptions = [],
  placeholder,
  label,
  autoComplete = false,
  hintText,
  hintErrorText,
  hintError,
  disabled,
  setSelectedOptions,
  selectedOptions,
  renderAutocompleteOption,
  transformOption = (option) => option,
  className,
  onItemsRendered,
  maxAutocompleteOptionsVisible = DEFAULT_MAX_VISIBLE_ITEMS,
  ...rest
}: ChipsInputT<T>) => {
  const chipsInputEl = React.useRef<HTMLInputElement>(null);
  const transparentInputEl = React.useRef<HTMLInputElement>(null);

  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [isInputOnFocus, setIsInputOnFocus] = React.useState(false);
  const [inputWidth, setInputWidth] = React.useState<number>(0);
  const [enablePopoverOpen, setEnablePopoverOpen] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(false);

  const popoverRef = React.useRef<HTMLDivElement | null>(null);

  // Close popover when clicking outside
  // This replaces the onInteractOutside prop from the Popover component, since it doesn't work inside modals
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        closePopover();
      }
    };

    if (enablePopoverOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [enablePopoverOpen]);

  // references and focus index indicator for the popover list items
  const [focusedIndex, setFocusedIndex] = React.useState<number>(-1);
  const listItemRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  React.useLayoutEffect(() => {
    if (!window.ResizeObserver) return;
    const updateWidth = () => {
      if (chipsInputEl.current) {
        setInputWidth(chipsInputEl.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);

    const currentElementRef = chipsInputEl.current;

    if (currentElementRef) {
      resizeObserver.observe(currentElementRef);
    }

    // Call updateWidth once initially to set the width
    updateWidth();

    // Clean up the observer when the component unmounts
    return () => {
      if (currentElementRef) {
        resizeObserver.unobserve(currentElementRef);
      }
    };
  }, []);

  React.useEffect(() => {
    if (transparentInputEl.current) {
      transparentInputEl.current.scrollTop =
        transparentInputEl.current.scrollHeight;
    }
  }, [selectedOptions]);

  const onSelectOption = React.useCallback(
    (option: ChipT) => {
      setSelectedOptions([...selectedOptions, transformOption(option)]);
      setIsInputOnFocus(false);
    },
    [selectedOptions, setSelectedOptions, transformOption],
  );

  const onAutocomplete = React.useCallback(
    (option: T) => {
      setSearchQuery('');
      onSelectOption(option);
    },
    [onSelectOption],
  );

  const dismissOption = (option: ChipT) => {
    const filtered = selectedOptions.filter((s) => s.id !== option.id);
    setSelectedOptions(filtered);
  };

  const popoverListItems = React.useMemo(() => {
    if (showOptionsOnFocus && searchQuery.length === 0 && isInputOnFocus)
      return autoCompleteOptions;
    return autoCompleteOptions?.filter((option) =>
      Object.values(option).some(
        (val) =>
          searchQuery.length > 0 &&
          val
            ?.toString()
            .toLowerCase()
            .replace(/\s/g, '') // searches without considering space characters
            .includes(searchQuery.toLowerCase()),
      ),
    );
  }, [autoCompleteOptions, searchQuery, showOptionsOnFocus, isInputOnFocus]);

  React.useEffect(() => {
    const shouldShowPopover =
      autoComplete &&
      popoverListItems?.length > 0 &&
      !disabled &&
      enablePopoverOpen;
    if (!shouldShowPopover) {
      setFocusedIndex(-1);
    }
    setShowPopover(shouldShowPopover);
  }, [autoComplete, popoverListItems, disabled, enablePopoverOpen]);

  // Update popover ref list when the list items change
  React.useEffect(() => {
    listItemRefs.current = popoverListItems.map(
      (_, index) => listItemRefs.current[index] || null,
    );
    if (focusedIndex >= 0) {
      listItemRefs.current[focusedIndex]?.focus();
    }
  }, [popoverListItems, focusedIndex]);

  // Keyboard navigation in the popover list should be up and down arrows instead of tabbing,
  // in order to be able to leave the transparen input field on tab
  const onKeyDownInPopover = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prev) =>
        Math.min(prev + 1, popoverListItems.length - 1),
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedIndex((prev) => Math.max(prev - 1, 0));
    }
  };

  const detectSeparator = (input: string) => {
    const trimmedInput = input.replace(/^,+/g, '').replace(/^ +/g, ''); // remove any leading space or comma
    const containsComma = trimmedInput.indexOf(',') > -1;
    const containsSpace = trimmedInput.indexOf(' ') > -1;
    if (containsComma || containsSpace) {
      const filteredUserInput = trimmedInput
        // @ts-expect-error-auto This works, but could be solved with a single regex
        // which would fix type safety
        .split(containsComma ? ',' : containsSpace && ' ')
        .filter(Boolean)
        .map((item) => item.trim());
      setSearchQuery('');
      saveNewOptions(filteredUserInput);
    }
  };

  const saveNewOptions = (filteredUserInput: string[]) => {
    const newFilteredUserInput = filteredUserInput
      .filter((option) => option !== ' ')
      .map((option) => transformOption(selectOption(option)));

    setSelectedOptions([...selectedOptions, ...newFilteredUserInput]);
  };

  const onSelect = (e: React.ClipboardEvent<HTMLInputElement>) => {
    detectSeparator(e.currentTarget.value);
  };

  const generateOption = (value: string) =>
    transformOption({
      value,
      id: `${id}-${value}-${'single-item'}-${Math.floor(
        Math.random() * 10000000000,
      )}`,
    });

  const findOption = (value: string) =>
    autoComplete
      ? autoCompleteOptions?.find(
          (option) => option.value.toLowerCase() === value.toLowerCase(),
        )
      : null;

  const selectOption = (value: string) =>
    findOption(value) || generateOption(value);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (e.key === 'Tab') {
      closePopover();
    } else if (e.key === 'Enter' && searchQuery !== '') {
      onSelectOption(selectOption(value));
      detectSeparator(value);
      setSearchQuery('');
    } else if (e.key === 'Backspace' && searchQuery === '') {
      if (selectedOptions.length >= 1) {
        const newSelectedOptions = selectedOptions.slice();
        newSelectedOptions.pop();
        setSelectedOptions(newSelectedOptions);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchQuery(value);
    detectSeparator(value);
  };

  const handleFocus = () => {
    if (autoComplete) {
      setEnablePopoverOpen(true);
    }
    setIsInputOnFocus(true);
  };

  const convertSearchQueryToChip = () => {
    onSelectOption(selectOption(searchQuery));
    detectSeparator(searchQuery);
    setSearchQuery('');
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!showPopover && searchQuery !== '') {
      convertSearchQueryToChip();
    } else {
      const popoverPressed = e.relatedTarget;
      if (!popoverPressed) {
        setIsInputOnFocus(false);
        if (searchQuery !== '') {
          convertSearchQueryToChip();
        }
      }
    }
  };

  const updateOption = (newOption: ChipT) => {
    setSelectedOptions(
      selectedOptions.map((option) =>
        option.id === newOption.id ? transformOption(newOption) : option,
      ),
    );
  };

  const renderOptionsVirtualized = React.useCallback(
    ({
      index,
      style,
      data,
    }: ListChildComponentProps<{
      itemRefs: (HTMLDivElement | null)[];
    }>) => {
      const item = popoverListItems[index]!;

      return (
        <Option
          item={item}
          onAutocomplete={onAutocomplete}
          style={style}
          ref={(el) => {
            data.itemRefs[index] = el;
          }}
          // @ts-expect-error-auto this looks like it could break in the <Option> component when undefined,
          // but in several places we don't set this prop at all. TODO: Look into intended usage.
          renderAutocompleteOption={renderAutocompleteOption}
        />
      );
    },
    [onAutocomplete, renderAutocompleteOption, popoverListItems],
  );

  const closePopover = () => {
    setEnablePopoverOpen(false);
  };

  return (
    <Box className={clsx(['flex-auto', 'mb-0', 'w-full'])}>
      {label ? <Label htmlFor={id}>{label}</Label> : null}

      <Box
        className={clsx(
          [
            'w-full',
            'h-full',
            'after:rounded-xl',
            'after:content-zwsp',
            'after:absolute',
            'after:top-0',
            'after:left-0',
            'after:w-full',
            'after:h-full',
            'after:pointer-events-none',
            'has-[#chipsinput-input-container>input:enabled:hover]:after:shadow-[inset_0_0_0_2px_var(--color-secondary)]',
            'has-[#chipsinput-input-container>input:enabled:focus]:after:shadow-[inset_0_0_0_2px_var(--color-secondary)]',
          ],
          className,
        )}
        {...rest}
      >
        <PopoverRoot open={showPopover}>
          <PopoverAnchor>
            <Box
              id={`${id}-container`}
              data-testid={`${id}-container`}
              ref={chipsInputEl}
              className={clsx([
                'bg-input',
                'text',
                'px-2',
                'py-1.5',
                'rounded-xl',
                'flex-row',
                'flex-wrap',
                'gap-1',
                'items-center',
                'overflow-auto',
                'w-full',
                'h-full',
                'max-h-[300px]',
                'outline-4',
                'outline-offset-2',
                'placeholder:text-weaker',
                'has-[#chipsinput-input-container>input:enabled:hover:not(:focus)]:bg-input',
                'has-[#chipsinput-input-container>input:focus]:bg',
                'has-[#chipsinput-input-container>input:focus]:text',
                'has-[#chipsinput-input-container>input:focus]:outline',
                'has-[#chipsinput-input-container>input:focus]:outline-interactive-focused',
                'has-[input:disabled]:bg-disabled-strong',
                'has-[input:disabled]:text-on-disabled-strong',
                'has-[input:disabled]:shadow-none',
                'has-[input:disabled]:placeholder:text-on-disabled-strong',
              ])}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown' && showPopover) {
                  e.preventDefault();
                  // Focus the first item in the list on arrow down
                  setFocusedIndex(0);
                }
              }}
            >
              {selectedOptions.map((sOption) => {
                return (
                  <Chip
                    key={sOption.id}
                    option={sOption}
                    isValid={sOption.isValid}
                    dismissOption={dismissOption}
                    disabled={disabled}
                    placeholder={placeholder}
                    onPaste={onSelect}
                    updateOption={updateOption}
                    onDismissed={() => transparentInputEl?.current?.focus()}
                  />
                );
              })}
              <Box
                id="chipsinput-input-container"
                className={clsx(['flex-auto', 'flex-row'])}
              >
                <TransparentInput
                  id={id}
                  value={searchQuery}
                  disabled={disabled}
                  autoComplete="off"
                  name={id}
                  placeholder={selectedOptions.length > 0 ? '' : placeholder}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onPaste={onSelect}
                  onKeyDown={onKeyDown}
                  ref={transparentInputEl}
                  inputSize="compact"
                  className={clsx([
                    '[&:enabled]:focus-visible:shadow-none',
                    '[&:enabled]:hover:shadow-none',
                    '[&:enabled]:focus-visible:outline-0',
                    'min-w-[200px]',
                    'h-[40px]',
                    'pl-2',
                  ])}
                />
              </Box>
            </Box>
          </PopoverAnchor>
          <PopoverContainer
            ref={popoverRef}
            id={`${id}-popover`}
            className="max-w-full sm:max-w-full mt-2"
            showArrow={false}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            onEscapeKeyDown={closePopover}
          >
            <PopoverBody
              style={
                {
                  '--chips-input-popover-width': `${inputWidth}px`,
                } as React.CSSProperties
              }
              className="w-[var(--chips-input-popover-width)] p-0"
            >
              <List
                itemData={{ itemRefs: listItemRefs.current }}
                height={
                  popoverListItems.length > maxAutocompleteOptionsVisible
                    ? ITEM_SIZE * maxAutocompleteOptionsVisible
                    : popoverListItems.length * ITEM_SIZE
                }
                width="100%"
                itemCount={popoverListItems.length}
                itemSize={ITEM_SIZE}
                onItemsRendered={onItemsRendered}
                innerElementType={InnerListElement(onKeyDownInPopover)}
              >
                {renderOptionsVirtualized}
              </List>
            </PopoverBody>
          </PopoverContainer>
        </PopoverRoot>
      </Box>
      <Text
        variant="ragnarBodySm"
        className={clsx(['mt-2', hintError ? 'text-negative' : 'text-weaker'])}
      >
        {hintError ? hintErrorText : hintText}
      </Text>
    </Box>
  );
};

export { ChipsInput };
