/* eslint-disable menti-react/filename-convention--jsx */
import { LocalStorage } from '@mentimeter/storage';
import {
  WorkspaceRoleEnum,
  type MembershipRoleT,
  type WorkspaceRole,
  TrackingContext,
} from '@mentimeter/http-clients';
import * as Yup from 'yup';
import { memberLiteRole, memberRole } from '@mentimeter/workspace-features';
import { trackUser } from '@api/tracking/client';
import type { BadgeT } from '@mentimeter/ragnar-ui/badge';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { Box } from '@mentimeter/ragnar-ui/box';
import { TooltipOld } from '@mentimeter/ragnar-ui/tooltip-legacy';
import type { PlacementT } from '@mentimeter/ragnar-ui/placement';
import type { ChipT } from '@mentimeter/ragnar-ui/chip';
import { INVITE_SELECTED_EMAILS_KEY } from './invite-for-admin-roles/InviteMembersNotifications';
import type { InviteMembersContextT } from './InviteMembersModal';

interface BadgeWithTooltip extends BadgeT {
  id: string;
  description: string;
  placement?: PlacementT;
}

export const BadgeWithTooltip = ({
  id,
  description,
  placement = 'right',
  ...rest
}: BadgeWithTooltip) => (
  <>
    <Box id={id}>
      <Badge {...rest} />
    </Box>
    <TooltipOld
      referenceId={id}
      placement={placement}
      description={description}
    />
  </>
);

export const parsedLocalEmails = () => {
  const localEmails =
    LocalStorage.getJSONItem<string>(INVITE_SELECTED_EMAILS_KEY) || '';
  return localEmails ? JSON.parse(localEmails) : [];
};

enum InvitationError {
  too_many_members = "You've reached the maximum number of members",
  too_many_member_lites = 'You have reached the maximum number of Member lite seats.',
  'Regional mismatch: User already exists!' = 'Regional mismatch: User already exists!',
  'Too many requests.' = 'Something went wrong. Please try again in a minute.',
}
type InvitationErrorT = keyof typeof InvitationError;

export const handleErrorMessage = ({
  errorMessage,
}: {
  errorMessage?: string | undefined;
}): string => {
  const invitationErrorMessage =
    InvitationError[errorMessage as InvitationErrorT];

  if (invitationErrorMessage) {
    if (invitationErrorMessage === InvitationError['too_many_members']) {
      return `${invitationErrorMessage}. Update the role or buy more licenses to invite more members.`;
    }
    return invitationErrorMessage;
  }
  if (errorMessage) {
    return errorMessage;
  }
  return 'Sorry, could not invite user';
};

export const getDefaultRole = ({
  hasLicensesLeft,
  isOwnerOrAdmin,
}: {
  hasLicensesLeft: boolean;
  isOwnerOrAdmin: boolean | undefined;
}): MembershipRoleT => {
  const adminsWithLicenses = isOwnerOrAdmin && hasLicensesLeft;
  const canInvitePaidMember = adminsWithLicenses;

  if (canInvitePaidMember) {
    return memberRole;
  }

  return memberLiteRole;
};

export function showHintError({
  licensesLeft,
  containsInvalidInput,
  error,
}: {
  licensesLeft: number;
  containsInvalidInput: boolean;
  error: string;
}): boolean {
  const showHint = licensesLeft < 0 || containsInvalidInput || error.length > 0;

  return showHint;
}

export const getLicensesLeft = ({
  availableLicenses,
  selectedMembers,
}: {
  availableLicenses: number | undefined;
  selectedMembers: number;
}) => {
  if (!availableLicenses) return 0;
  return availableLicenses - selectedMembers;
};

export const getValidateEmail = (option: ChipT) => {
  const emailValidationSchema = Yup.string().email(
    'Please fill in an email address',
  );
  try {
    emailValidationSchema.validateSync(option.value);
    return true;
  } catch (e) {
    return false;
  }
};

export const containsInvalidInput = (selectedMembers: ChipT[]): boolean => {
  return selectedMembers.some((m) => !getValidateEmail(m));
};

export const overCommittedLicenses = ({
  isFreeUser,
  selectedMembers,
  availableLicenses,
}: {
  isFreeUser: boolean;
  selectedMembers: ChipT[];
  availableLicenses: number;
}): number => {
  return isFreeUser ? 0 : selectedMembers.length - availableLicenses;
};

export const transformInviteInput = (
  invite: ChipT,
  validateEmail: (option: ChipT) => boolean,
): ChipT => {
  return {
    ...invite,
    value: invite.value.toLowerCase(),
    isValid: validateEmail(invite),
  };
};

export function trackSendInvitationButtonClick({
  checkedGroups,
  selectedEmails,
  context,
  activeTeamMembers,
  currentPlan,
  roleInWorkspace,
  invitationRole,
}: {
  checkedGroups: Array<string>;
  selectedEmails: Array<string>;
  context: InviteMembersContextT;
  activeTeamMembers: number;
  currentPlan: string;
  roleInWorkspace: WorkspaceRole;
  invitationRole: WorkspaceRole;
}) {
  const submitContext =
    context === TrackingContext.ManageMembers
      ? 'Invite members modal'
      : context;
  trackUser({
    event: 'Clicked send invitation',
    properties: {
      context: submitContext,
      'group ids': checkedGroups,
      'number of invited users': selectedEmails.length,
      'active team members': activeTeamMembers,
      'current plan': currentPlan,
      'role in workspace': roleInWorkspace,
      'invitation role': invitationRole,
    },
  });
}

export function pluralize({
  word,
  count,
  pluralSuffix = 's',
}: {
  word: string;
  count: number;
  pluralSuffix?: string;
}): string {
  return count === 1 ? word : `${word}${pluralSuffix}`;
}

export function getPositiveNumber(value: number): number {
  return value > 0 ? value : 0;
}

export const getSelectedMemberLites = ({
  activeRoleName,
  selectedMembersCount,
}: {
  activeRoleName: WorkspaceRole;
  selectedMembersCount: number;
}): number => {
  return activeRoleName === WorkspaceRoleEnum.MEMBER_LITE
    ? selectedMembersCount
    : 0;
};

export function getRestrictedBySettings({
  userRole,
  anyoneCanInvite,
  hasInvitePermissionSettingsEnabled,
}: {
  userRole: WorkspaceRole;
  anyoneCanInvite: boolean;
  hasInvitePermissionSettingsEnabled: boolean;
}) {
  const isAdmin =
    userRole === WorkspaceRoleEnum.ADMIN ||
    userRole === WorkspaceRoleEnum.OWNER;
  const shouldRestrictNonAdmins =
    !isAdmin && hasInvitePermissionSettingsEnabled && !anyoneCanInvite;

  return shouldRestrictNonAdmins;
}
