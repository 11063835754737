import * as React from 'react';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Strong } from '@mentimeter/ragnar-ui/typography';

export const ToastContent = ({ children }: { children: React.ReactNode }) => {
  return <Text color="inherit">{children}</Text>;
};

interface InviteToastProps {
  newInvites: string[];
  resentInvites: string[];
  existingMembers: string[];
}

export const InviteToast = ({
  newInvites,
  resentInvites,
  existingMembers,
}: InviteToastProps) => {
  const boldText = (text: string | number) => (
    <Strong color="inherit">{text}</Strong>
  );

  if (newInvites.length > 0) {
    return (
      <ToastContent>
        {newInvites.length === 1 && newInvites[0] ? (
          <>Sent invitation to {boldText(newInvites[0])}</>
        ) : (
          <>Sent invitations to {boldText(newInvites.length)} members</>
        )}
      </ToastContent>
    );
  }

  if (resentInvites.length > 0) {
    return (
      <ToastContent>
        {resentInvites.length === 1 && resentInvites[0] ? (
          <>{boldText(resentInvites[0])} was invited again</>
        ) : (
          <>{boldText(resentInvites.length)} members were invited again</>
        )}
      </ToastContent>
    );
  }

  if (existingMembers.length > 0) {
    return (
      <ToastContent>
        {existingMembers.length === 1 && existingMembers[0] ? (
          <>{boldText(existingMembers[0])} is already a member</>
        ) : (
          <>
            {boldText(existingMembers.length)} of the invitees are already
            members
          </>
        )}
      </ToastContent>
    );
  }

  return null;
};
