import type * as React from 'react';
import { trackClickUpgrade } from '@mentimeter/dashboard-tracking';
import { trackUser } from '@api/tracking/client';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';

export type PlansHrefT =
  | '/plans'
  | '/app/checkout/pro'
  | '/app/checkout/educational-pro'
  | '/app/checkout/basic'
  | '/app/checkout/educational-basic'
  | '/app/checkout/educational-basic-monthly'
  | '/app/checkout/basic-monthly';

export const upgradeButton = ({
  variant = 'positive',
  children = 'Upgrade',
  trackingContext,
  href,
  queryString,
  storeLocalData,
}: {
  trackingContext: string;
  href: PlansHrefT;
  variant?: ButtonT['variant'];
  children?: React.ReactNode;
  queryString?: string | undefined;
  storeLocalData?: () => void | undefined;
}) => ({
  key: 'upgrade',
  href: [href, queryString].filter(Boolean).join('?'),
  target: '_blank',
  variant,
  children,
  onClick: () => {
    storeLocalData?.();
    trackClickUpgrade({
      event:
        href === '/plans' ? 'Plans from homeview' : 'Checkout from homeview',
      properties: { context: trackingContext },
    });
  },
});

export const contactSalesButton = ({
  variant,
  trackingContext,
  onClick,
  children = 'Contact sales',
}: {
  variant: ButtonT['variant'];
  trackingContext: string;
  onClick: () => void;
  children?: React.ReactNode;
}) => ({
  key: 'contact-sales',
  'aria-label': 'Contact sales',
  variant,
  children,
  onClick: () => {
    trackUser({
      event: 'Clicked contact sales',
      properties: { context: trackingContext },
    });
    onClick();
  },
});

export const learnMoreButton = ({ href }: { href: string | undefined }) => ({
  key: 'learn-more',
  href: `${href}`,
  target: '_blank',
  children: 'Learn more',
});
