export function hintErrorText(
  containsInvalidInput: boolean,
  removableAddresses: number,
  error: string,
) {
  if (containsInvalidInput) {
    return 'At least one of the email addresses does not have the correct format of example@mail.com. Please adjust and try again.';
  }
  if (removableAddresses > 0) {
    return `Too many email addresses! Buy ${removableAddresses} more ${
      removableAddresses === 1 ? 'license' : 'licenses'
    } to invite.`;
  }
  return error;
}
